import { ArrowForward, Check, Pending, Upload } from '@mui/icons-material';
import React, { useState, useEffect, useContext } from 'react';
import UploadModal from './uploadModal';


const Tier4 = ({uploadDoc, postData, setPostData, showModal, hideModal, kycStatus, level3}) => {
//  const [] = useState(false);
// const [postData, setPostData] = useState({})
const [name, setName] = useState('');
const [cac, setCac] = useState([]);
const [tin, setTin] = useState([]);
const [reference, setReference] = useState([]);
const rejected='';
const pending='';
const upload='';
const check='';



const {
    userCAC,
    userTIN,
    mermatStatus,
  } = kycStatus;

useEffect(() => {
  const cac1 = kycStatus?.tier4?.filter(
    (e) => e?.requirementType === 'CAC'
  )[0];
  const tin1 = kycStatus?.tier4?.filter(
    (e) => e?.requirementType === 'TIN'
  )[0];
  const reference1 = kycStatus?.tier4?.filter(
    (e) => e?.requirementType === 'REFERENCE'
  )[0];
  setCac(cac1);
  setTin(tin1);
  setReference(reference1);
}, [])
    return (
        <div className="tier" id="tier4">
          {/*<div className="head my-5">Upgrade KYC Level</div>
          <div className="info my-3">
            <p className="info-1">Tier 4</p>
            <div className="info-2">
              <p className="info-2-t">status</p>
              <p
                className={`${
                  userAddress === true &&
                  kycStatus?.CAC?.status === true &&
                  tinStatus !== null &&
                  mermatStatus !== null
                    ? 'info-2-b-true'
                    : 'info-2-b-false'
                } px-3 py-1`}
              >
                {userAddress === true &&
                kycStatus?.CAC?.status === true &&
                tinStatus !== null &&
                mermatStatus !== null
                  ? 'Completed'
                  : 'Not Completed'}
              </p>
            </div>
          </div>*/}
          <div >
            <table className="w-100">
              <thead>
                <tr className="text-center ">
                  <th className="p-2 w-50">Kyc Requirement</th>
                  <th>Action</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr className="">
                  <td className="p-3">CAC Certificate </td>
                  <td className="d-flex flex-column">
                  <button
                    disabled={cac?.status === 'APPROVED'}
                      className="action btn my-2 btn-primary"
                      onClick={() => {
                        hideModal(true);
                        setName('cac');
                      }}
                    >
                      <img src={Upload} alt="" className="me-2" />
                      <span>Upload</span>
                    </button>
                  </td>
                  <td>
                    <div
                      className={
                        cac.status === 'APPROVED'
                          ? 'status-true'
                          : 'status-pending'
                      }
                    >
                      <img
                        src={
                          cac.status === 'APPROVED'
                            ? check
                            : cac.status === 'REJECTED'
                            ? rejected
                            : pending
                        }
                        alt=""
                        className="me-2"
                      />
                      <span>
                        {cac.status}
                      </span>
                    </div>
                  </td>
                </tr>
                <tr className="">
                  <td className="p-3">Tax Indentification Number</td>
                  <td className="d-flex flex-column">
                  <button
                    disabled={tin?.status === 'APPROVED'}
                      className="action btn my-2 btn-primary"
                      onClick={() => {
                        hideModal(true);
                        setName('tin');
                      }}
                    >
                      <img src={upload} alt="" className="me-2" />
                      <span>Upload</span>
                    </button>
                  
                  </td>
                  <td>
                    <div
                      className={
                        tin.status === 'APPROVED'
                          ? 'status-true'
                          : tin.status === 'REJECTED'
                          ? 'status-false'
                          : 'status-pending'
                      }
                    >
                      <img
                        src={
                          tin.status === 'APPROVED'
                            ? check
                            : tin.status === 'REJECTED'
                            ? rejected
                            : pending
                        }
                        alt=""
                        className="me-2"
                      />
                      <span>
                        {tin.status}
                      </span>
                    </div>
                  </td>
                </tr>
                <tr className="">
                  <td className="p-3">Reference</td>
                  <td className="d-flex flex-column">
                  <button
                    disabled={reference?.status === 'APPROVED'}
                      className="action btn my-2 btn-primary"
                      onClick={() => {
                        hideModal(true);
                        setName('reference');
                      }}
                    >
                      <img src={upload} alt="" className="me-2" />
                      <span>Upload</span>
                    </button>                  
                  </td>
                  <td>
                    <div
                      className={
                        reference?.status === 'APPROVED'
                          ? 'status-true'
                          : reference?.status === 'REJECTED'
                          ? 'status-false'
                          : 'status-pending'
                      }
                    >
                      <img
                        src={
                          reference.status === 'APPROVED'
                            ? check
                            : reference.status === 'REJECTED'
                            ? rejected
                            : pending
                        }
                        alt=""
                        className="me-2"
                      />
                      <span>
                        {reference?.status}
                      </span>
                    </div>
                  </td>
                </tr>
                {/* <tr className="">
                  <td className="p-3">Mermat</td>
                  <td className="d-flex flex-column">
                    <div
                      className="action btn my-2 btn-primary"
                      onClick={() => {
                        hideModal(true);
                        setName('MERMAT');
                      }}
                    >
                      <img src={upload} alt="" className="me-2" />
                      <span>Upload</span>
                    </div>
                  </td>
                  <td>
                    
                    <div
                      className={
                        mermatStatus === null ? 'status-pending' : 'status-true'
                      }
                    >
                      <img
                        src={mermatStatus === null ? pending : check}
                        alt=""
                        className="me-2"
                      />
                      <span>
                        {mermatStatus === null ? 'Pending' : [mermatStatus]}
                      </span>
                    </div>
                  </td>
                </tr> */}
              </tbody>
            </table>
          </div>
          
          {showModal && 
          <UploadModal
            showModal={showModal}
            hideModal={hideModal}
            postData={postData}
            setPostData={setPostData}
            name={name}
            uploadDoc={uploadDoc}
          />}
        </div>
    )
}
export default Tier4