import { Slide, toast } from "react-toastify";
import axios from "../plugins/axios";
import { updateProfileCorporate, viewMerchantTerminals, toggle2Fa, get2FaStatus } from "../plugins/urls";

let user;
try {
  user = JSON.parse(localStorage.getItem("userDetails"))?.user;
} catch {
  user = {};
}

export const apiCall = ({
  data,
  url,
  method,
  setLoading,
  success,
  error,
  defaultMeta,
  meta,
  noError,
  isSuccess,
  token,
}) => {
  if (defaultMeta) {
    meta = {
      dataIndex: "respBody",
      codeIndex: "respCode",
      codeValue: "Successful",
      messageIndex: "respDesc",
    };
  }
  setLoading && setLoading(true);
  axios({
    method,
    headers: {
      'Authorization': token ?? localStorage.getItem('token')
    },
    url,
    data,
  })
    .then((res) => {
      setLoading && setLoading(false);
      if (
        res.data.status === true ||
        res.status === true ||
        res.status === 200
      ) {
        return success && success(res.data[meta?.dataIndex] || res.data);
      }
      if (typeof meta === "object") {
        const resData = res.data ?? res;
        if (
          resData[meta?.messageIndex] === meta?.codeValue ||
          isSuccess?.(res.data)
        )
          return success && success(res.data[meta?.dataIndex] || res.data);
        const err =
          typeof resData[meta?.dataIndex] === "string"
            ? resData[meta?.dataIndex]
            : resData[meta?.messageIndex];
        error && error(err);
        return (
          !noError &&
          toast.error(err || "Request failed", {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          })
        );
      } else {
        error && error(res?.data?.message);
        return (
          !noError &&
          toast.error(
            (typeof res?.data === "string" && res?.data) ||
              res?.data?.message ||
              "Request failed",
            {
              transition: Slide,
              hideProgressBar: true,
              autoClose: 3000,
            }
          )
        );
      }
    })
    .catch((err) => {
      // if (){
      const errRes = { ...err };
      const errData = errRes?.response?.data ?? errRes?.response;
      setLoading && setLoading(false);
      if (typeof meta === "object" && errData) {
        const err =
          typeof errData[meta?.dataIndex] === "string"
            ? errData[meta?.dataIndex]
            : errData[meta?.messageIndex];
        error && error(err);
        return (
          !noError &&
          toast.error(err || "Request failed", {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          })
        );
      } else {
        error && error(errData?.message);
        return (
          !noError &&
          toast.error(
            (typeof errData === "string" && errData) ||
              errData?.message ||
              err.message ||
              "Request failed",
            {
              transition: Slide,
              hideProgressBar: true,
              autoClose: 3000,
            }
          )
        );
      }
    });
};
export const downloadExcel = ({
  url,
  method = "post",
  data,
  fileName,
  resIndex,
  resIndex2,
}) =>
  axios({
    method,
    url,
    data,
  })
    .then((response) => {
      return response;
    })
    .then((blob) => {
      // Create blob link to download
      let url = null;
      if (resIndex2) {
        if (blob.data[resIndex][resIndex2]?.length > 0) {
          url = window.URL.createObjectURL(
            new Blob([blob.data[resIndex][resIndex2]])
          );
        } else {
          toast.error("Cannot export empty data", {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
        }
      } else {
        if (blob.data[resIndex]?.length > 0) {
          url = window.URL.createObjectURL(new Blob([blob.data[resIndex]]));
        } else {
          toast.error("Cannot export empty data", {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
        }
      }

      const link = document.createElement("a");
      link.href = url;

      link.setAttribute("download", `${fileName}_${new Date()}.xls`);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      url !== null && link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    });

export const updateProfile = ({ data, setUpdating, updateSuccess }) => {
  const {
    firstName,
    gender,
    city,
    phoneNumber,
    officeAddress,
    dateOfBirth,
    district,
  } = data;

  const payload = {
    firstName,
    gender,
    surname: data.lastName,
    city,
    phoneNumber,
    officeAddress,
    dateOfBirth,
    district,
  };

  setUpdating(true);
  axios
    .post(updateProfileCorporate, payload)
    .then((res) => {
      if (res.status === 200 && res.data.respCode === 0) {
        updateSuccess(data);
      } else {
        toast.error(`${res.data.respBody}`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      toast.error(err.data.resDesc, {
        transition: Slide,
        hideProgressBar: true,
        autoClose: 3000,
      });
    })
    .finally(() => {
      setUpdating(false);
    });
};
export const toggleTwoFa = ({ userId }) => {
  const payload = {    
  };

  //  setUpdating(true);
  axios
    .post(toggle2Fa({userId}), payload)
    .then((res) => {
      if (res.status) {
        //  updateSuccess(userId);
      } else {
        toast.error(`${res.data.respBody}`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      toast.error(err.data, {
        transition: Slide,
        hideProgressBar: true,
        autoClose: 3000,
      });
    })
    .finally(() => {
      //  setUpdating(false);
    });
};
export const getTwoFaStatus = ({ userId }) => {
  const payload = {    
  };

  //  setUpdating(true);
  axios
    .get(get2FaStatus({userId}))
    .then((res) => {
      if (res.status) {
        return res
        //  updateSuccess(userId);
      } else {
        toast.error(`${res?.data?.message}`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      toast.error(err.data, {
        transition: Slide,
        hideProgressBar: true,
        autoClose: 3000,
      });
    })
    .finally(() => {
      //  setUpdating(false);
    });
};
export const getMerchantTerminals = ({ successCallback, id }) => {
  let reqBody = {
    from: "",
    to: "",
    pageNo: 1,
    pageSize: 10,
    params: {
      id: id || user?.id,
    },
  };

  axios({
    method: "post",
    url: `${viewMerchantTerminals}`,
    data: reqBody,
  })
    .then((res) => {
      if (res.data.respCode === 0) {
        successCallback(res.data.respBody);
      }
    })
    .catch((err) => {
      toast.error(`${err.response.data.message}`, {
        transition: Slide,
        hideProgressBar: true,
        autoClose: 3000,
      });
    });
};
