import React, { useState, useEffect, useContext } from 'react';
import swal from 'sweetalert';
import { Modal, ModalBody } from 'reactstrap';
//import PinModal from '../../shared/Modals/PinModal';
import { Button } from 'react-bootstrap';
import PinModal from 'pages/AuthPages/twoFa';
import useUser from 'components/Hooks/useUser';
import { generateEmailOtp, generatePhoneOtp, generateVerifyOtp, wayaVerifyEmail, wayaVerifyPhone } from 'plugins/urls';
import { Slide, toast } from 'react-toastify';
import { apiCall } from 'utilities/apis';

export default function VerifyEmailAndPhone(props) {
  const { showAlert, type, setShowAlert, profile } = props;
  const [showPin, setShowPin] = useState(false);
  const [otp, setOtp] = useState('');
  const [resendVerifyEmailOtp, setResendVerifyEmailOtp] = useState(false);
 
  useEffect(() => {
    if (resendVerifyEmailOtp) console.log('resend otp to email');
    return () => {
      setResendVerifyEmailOtp(false);
    };
  }, [resendVerifyEmailOtp]);
  const { user } = useUser();

  const handleVerifyEmail = async () => {
   
    apiCall({
        method: "get",
        url: `${generateEmailOtp}${user.email}?businessId=${user?.posUserProfile?.loginId}`,
        success: (data) => {
                toast.success( data?.message , {
                    transition: Slide,
                    hideProgressBar: true,
                    autoClose: 3000,
                  })
        },
      });   

  };
 
  const handleVerifyEmailOtp = async () => {
    const data = {
        phoneOrEmail: user?.email,
        businessId: user?.posUserProfile?.loginId,
        otp
    } 

    apiCall({
        url: `${wayaVerifyEmail}`,
        method: "post",
        data,
        success: (resp) => {
          if(resp?.body?.status){
            handleClose();
          toast.success( resp?.body?.message ?? resp?.message, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          })  
        } else {
          toast.error( resp?.body?.message ?? resp?.message, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          })  
        }     
      },
      });
  };

  const handleResendOtpPhone = async () => {
    setShowPin(true);

    apiCall({
        method: "get",
        url: `${generatePhoneOtp}${user.phoneNumber}?businessId=${user?.posUserProfile?.loginId}`,
        success: (data) => {
            toast.success( data?.message , {
                transition: Slide,
                hideProgressBar: true,
                autoClose: 3000,
              }) 
        },
      });  
  };

  const handleVerifyPhoneOtp = async () => {
    const data = {
        phoneOrEmail: user?.phoneNumber,
        businessId: user?.posUserProfile?.loginId,
        otp
    } 

    apiCall({
        url: `${wayaVerifyPhone}`,
        method: "post",
        data,
        success: (resp) => {
          if(resp?.body?.status){
           handleClose();
          toast.success( resp?.body?.message ?? resp?.message, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          })  
        } else {
          toast.error( resp?.body?.message ?? resp?.message, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          })  
        }     
      },
      }); 
  };

  const handleSendOtpTo = () => {
    if (type === 'verifyEmail') {
      handleVerifyEmailOtp();
    } else {
      handleVerifyPhoneOtp();
    }
  };
  const handleClose =()=>{
    setShowAlert(false)
    setShowPin(false)
  }
  return (
    // <div
    //   className={!showAlert ? 'd-none' : 'text-center notification-div mx-auto'}
    // >
    <>
    {!showPin?(<Modal
      isOpen={showAlert}
      toggle={() => setShowAlert(false)}
      centered
      id="verification"
    >
      <ModalBody>
       {type === 'verifyEmail' && (
          <div className="">
            <p className="text-center">
              Click on Verify to recieve OTP to your registered Email address
              for Verififcation
            </p>
            <div className="w-100 mt-5 text-center">
              <Button
                type="button"
                className="btn btn-primary btn-lg w-50"
                loading={false}
                disabled={false}
                onClick={() => {
                  handleVerifyEmail();
                  setShowPin(true);
                }}
                content="verify"
              />
            </div>
          </div>
        )}

        {type === 'verifyPhone' && (
          <div className="">
            <p className="text-center">
              Click on Verify to recieve OTP to your registered Phone Number for
              Verififcation
            </p>
            <div className="w-100 mt-5 text-center">
              <button
                type="button"
                className="btn btn-primary btn-lg w-50"
                loading={false}
                disabled={false}
                onClick={() => {
                  handleResendOtpPhone();
                }}
                content="verify"
              >
                Verify
              </button>
            </div>
          </div>
        )}
       

        
      </ModalBody>
      
    </Modal>) :
            (
          <PinModal
            center
            showModal={showPin}
            hideModal={handleClose}
            OTP={otp}
            setOTP={setOtp}
            numInputs={6}
            separator=""
            buttonLabel="Verify"
            title="Verify Your Account"
            description={
              type === 'verifyEmail'
                ? 'email'
                : 'phone'
            }
            type={type === 'verifyEmail' ? 'email' : 'phone'}
            isResendOtp
            resendOtp={setResendVerifyEmailOtp}
            handleSubmit={handleSendOtpTo}
          />
        ) }
    </>
  );
}
