import { ArrowForward, Check, Pending } from '@mui/icons-material';
import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';

import React, { useState, useEffect, useContext } from 'react';
import UploadModal from './uploadModal';
import useUser from 'components/Hooks/useUser';
import VerifyEmailAndPhone from './verifyEmail';
import { inputBox } from './temps';


const Tier1 = ({formData, kycStatus, requirements, verify, handleChange, loading, loadingBvn, openBvn, setOpenBvn, bvn}) => {
//  const [] = useState(false);
// const [postData, setPostData] = useState({})
const [name, setName] = useState('');
const rejected='';
const pending='';
const upload='';
const check='';

const { user } = useUser();

const [userDetails, setUserDetails] = useState(user);
const [showAlert, setShowAlert] = useState(false);
  const [showAlertType, setShowAlertType] = useState('');
  const [userAddress, setUserAddress] = useState();
  const [phone, setPhone] = useState([]);
  const [email, setEmail] = useState([]);

useEffect(()=>{
console.log('user', user);
console.log('userDetaild', userDetails)

},[])
useEffect(() => {
  const phone1 = kycStatus?.tier1?.filter(
    (e) => e?.requirementType === 'PHONE_NUMBER'
  )[0];
  const email1 = kycStatus?.tier1?.filter(
    (e) => e?.requirementType === 'EMAIL'
  )[0];
  
  setPhone(phone1);
  setEmail(email1);
}, []);


    return (
        <div className="tier" id="tier1">      
          <div >
            <table className="w-100">
            {/*<thead>
                <tr className="text-center ">
                  <th className="p-2 w-50">Kyc Requirement</th>
                  <th>Action</th>
                  <th>Status</th>
                </tr>
              </thead>*/}
              <tbody>
                <tr className="">
                  <td className="p-3">Phone Number Verification</td>
                  <td className="p-3">{phone?.verificationReference}</td>

                  <td>
                    <div
                      onClick={() => {
                        setShowAlertType('verifyPhone');
                        setShowAlert(true);
                      }}
                      className={`action ${
                        phone?.status==='APPROVED' ? 'btn disabled' : 'btn'
                      } my-2`}
                    >
                      {! phone?.status === 'APPROVED' && <ArrowRightAltOutlinedIcon />}

                      <span>
                        { phone?.status ===' APPROVED'
                          ? 'Verified'
                          : 'Verify'}
                      </span>
                    </div>
                  </td>
                  <td>
                    <div
                      className={
                        phone?.status === 'APPROVED'
                          ? 'status-true'
                          : 'status-pending'
                      }
                    >
                      <img
                        src={
                           phone?.status === 'APPROVED' ? check : pending
                        }
                        alt=""
                        className={`me-2 ${
                           phone?.status === 'APPROVED'
                            ? 'status-true'
                            : 'status-pending'
                        }`}
                      />
                      <span>
                        { phone?.status === 'APPROVED'
                          ? 'Verified'
                          : 'Pending'}
                      </span>
                    </div>
                  </td>
                </tr>
                <tr className="">
                  <td className="p-3">Email Verification</td>
                  <td className="p-3">{email?.verificationReference}</td>

                  <td>
                    <div
                      onClick={() => {
                        setShowAlertType('verifyEmail');
                        setShowAlert(true);
                      }}
                      className={`action ${
                         email?.status  === 'APPROVED' ? 'btn disabled' : 'btn'
                      } my-2`}
                    >
                     {! email?.status  === 'APPROVED' && <ArrowRightAltOutlinedIcon />}

                      <span>
                        {' '}
                        { email?.status === 'APPROVED'
                          ? 'Verified'
                          : 'Verify'}
                      </span>
                    </div>
                  </td>
                  <td>
                    <div
                      className={
                         email?.status === 'APPROVED'
                          ? 'status-true'
                          : 'status-pending'
                      }
                    >
                      <img
                        src={
                           email?.status === 'APPROVED' ? check : pending
                        }
                        alt=""
                        className={`me-2 ${
                           email?.status === 'APPROVED'
                            ? 'status-true'
                            : 'status-pending'
                        }`}
                      />
                      <span>
                        { email?.status === 'APPROVED'
                          ? 'Verified'
                          : 'Pending'}
                      </span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            {requirements?.map(({name, label, placeholder, canVerify, numberInput}) => inputBox(label, name, verify, handleChange, formData, canVerify, placeholder, numberInput, loadingBvn, openBvn, setOpenBvn, bvn))}
          </div>         
          {showAlert ? (
        <VerifyEmailAndPhone
          showAlert={showAlert}
          type={showAlertType}
          setShowAlert={setShowAlert}
        />
      ) : (
        ''
      )}
        </div>
    )
}
export default Tier1