import { Box, Button } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import { tier1Limits } from "./temps";
import useKyc from "./useKyc";
import SubmitLoader from "../../../../../../components/SubmitLoader/SubmitLoader";
import Loader from "../../../../../../components/Loader";
import useUser from "components/Hooks/useUser";
import { useEffect } from "react";
import { useState } from "react";

UpgradeKYC.propTypes = {
  setCurrentTab: PropTypes.func,
  currentTab: PropTypes.object,
};

export default function UpgradeKYC({ setCurrentTab, currentTab, level }) {
  const {
    displayForm,
    tier,
    nextTier,
    previousTier,
    saveKyc,
    loading,
    limits,
    status,
    kycStatus,
    bvnData,
  } = useKyc({ level });
  const {user} = useUser();
  const [docs, setDocs] = useState([]);
  const [email, setEmail] = useState([]);
  const [phone, setPhone] = useState([]);
  const [passport, setPassport] = useState([]);
  const [id, setId] = useState([]);
  const [utility, setUtility] = useState([]);
  const [cac, setCac] = useState([]);
  const [tin, setTin] = useState([]);
  const [reference, setReference] = useState([]);
  const [bvn, setBvn] = useState([]);
  const [limit, setLimit] = useState([]);

  
  useEffect(()=>{
    const email1 = kycStatus?.tier1?.filter(
      (e) => e?.requirementType === 'EMAIL'
    )[0];
    const phone1 = kycStatus?.tier1?.filter(
      (e) => e?.requirementType === 'PHONE_NUMBER'
    )[0];
    const bvn1 = kycStatus?.tier2?.filter(
      (e) => e?.requirementType === 'BVN'
    )[0];
    const passport1 = kycStatus?.tier3?.filter(
      (e) => e?.requirementType === 'PHOTO'
    )[0];
    const id1 = kycStatus?.tier3?.filter(
      (e) => e?.requirementType === 'ID'
    )[0];
    const utility1 = kycStatus?.tier3?.filter(
      (e) => e?.requirementType === 'UTILITY'
    )[0];
    const cac1 = kycStatus?.tier4?.filter(
      (e) => e?.requirementType === 'CAC'
    )[0];
    const tin1 = kycStatus?.tier4?.filter(
      (e) => e?.requirementType === 'TIN'
    )[0];
    const reference1 = kycStatus?.tier4?.filter(
      (e) => e?.requirementType === 'REFERENCE'
    )[0];
   const tierLevel = kycStatus[`tier${tier}`] ?? kycStatus['tier1'] ;
   const {tier1, tier2, tier3, tier4} = kycStatus;
   if(tierLevel?.length>0){
   const lim = {
    maxNumberOfTxnAllowed: tierLevel[0] ? tierLevel[0]?.dailyTransactionLimitCount : 0,
    maximumLimit: tierLevel[0] ? tierLevel[0]?.dailyTransactionLimit : 0,
    singleTransferLimit: tierLevel[0] ? tierLevel[0]?.singleTransactionLimit : 0
   }
   setLimit(lim);
  }
    console.log('email', email1?.status)
    setEmail(email1);
    setPhone(phone1);
    setBvn(bvn1);
    setPassport(passport1);
    setId(id1);
    setUtility(utility1);
    setCac(cac1);
    setTin(tin1);
    setReference(reference1);
  
  }, [kycStatus])
 console.log('kkk', kycStatus)
  const tier1Status = email?.status === 'APPROVED' ||  phone?.status === 'APPROVED';
  const tier2Status = bvn?.status === 'APPROVED';
  const tier3Status = passport?.status === "APPROVED" &&
    id?.status === "APPROVED" &&
  utility?.status === "APPROVED"

    const tier4Status = tin?.status === "APPROVED" &&
      cac?.status === "APPROVED" && reference?.status === "APPROVED"

    const statColors = 
    (tier == 1) ? (tier1Status?'success' : 'danger')
    :(tier == 2) ? (tier2Status?'success' : 'danger')
    : (tier == 3) ? (tier3Status?'success' : 'danger')
    : (tier == 4) ? (tier4Status?'success' : 'danger') 
    : status[`tier${tier}`] === true ? "success" : "danger";
  
    return loading ? (
    <Loader />
  ) : (
    <div>
      <div className="row">
        <div className="col-5">
          <Button onClick={() => setCurrentTab({ component: null })}>
            <div className="d-flex" style={{ color: "black" }}>
              <KeyboardBackspaceOutlinedIcon />
              <small className="ml-1">Back </small>
            </div>
          </Button>
        </div>
        <div className="col-7 bold">Upgrade KYC Level</div>
      </div>
      <Box className="border-left border-right border-top border-bottom bg-white p-4">
        <div className="d-flex justify-content-between">
          <div className="bold">{`Tier ${tier}`}</div>
          <div className="d-flex align-baseline">
            <div>
              <small>Status</small>
            </div>
            <div className={`text-${statColors} ms-2`}>
            {tier == 1 ? (tier1Status ? "completed": "incomplete")
              :tier == 2 ? (tier2Status ? "completed": "incomplete")
                : tier == 3 ? (tier3Status ? "completed": "incomplete") 
                  : tier == 4 ? (tier4Status ? "completed": "incomplete")
                    :  status[`tier${tier}`] === true ? "Completed" : "Incomplete"}
                {//status[`tier${tier}`] === true ? "Completed" : "Incomplete"
              }
            </div>
          </div>
        </div>
        <Box py={2} className="bold kyc-box-shadow ">
          KYC Requirement
        </Box>
        <div className="kycBox pt-4 border-left border-bottom border-right pb-2">
          {currentTab?.form || displayForm[tier]}
        </div>
        <Box pb={15} mt={5}>
          <div className="d-md-flex justify-content-between">
            {/*</div>*/}
            <div className="heading">You are limited to:</div>

            {tier === 5 && (
              <button
                onClick={saveKyc}
                className="border-radius-5 mr-2 px-3 p-1 border-right border-left border-top border-bottom d-flex orange-button"
                style={{ marginTop: "-4.5px" }}
              >
                {loading ? (
                  <SubmitLoader className="left-43" />
                ) : (
                  <small>Save Changes</small>
                )}
              </button>
            )}
          </div>
          {tier1Limits(previousTier, nextTier, kycStatus, tier, limit, loading)}
        </Box>
      </Box>
    </div>
  );
}
