// @mui
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import NumberFormat from "react-currency-format";

import Loader from "../../../../../components/Loader";
import { makeFullName } from "../../../../../utilities/stringOperations";
import ProfileAvatarComponent from "./ProfileAvatarComponent";
export default function UpdateProfileForm({ formData, setFormData, updating }) {
  const handleDateChange = (date) => {
    setFormData({
      ...formData,
      dateOfBirth: date,
      edited: {
        ...formData.edited,
        dateOfBirth: date,
      },
    });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
      edited: {
        ...formData.edited,
        [name]: value,
      },
    });
  };
  return updating ? (
    <Stack spacing={3}>
      <Loader />
    </Stack>
  ) : (
    <Stack spacing={3}>
      <ProfileAvatarComponent
        name={makeFullName([formData.firstName, formData.surname])}
        hideName
        upload
      />

      <TextField
        value={formData.firstName}
        onChange={handleChange}
        name="firstName"
        disabled
        label={
          <p
            style={{
              fontSize: ".8rem",
              textTransform: "uppercase",
              color: "#FF6700",
              fontWeight: "700",
            }}
          >
            first name
          </p>
        }
        variant="standard"
      />

      <TextField
        value={formData.surname}
        onChange={handleChange}
        name="lastName"
        disabled
        label={
          <p
            style={{
              fontSize: ".8rem",
              textTransform: "uppercase",
              color: "#FF6700",
              fontWeight: "700",
            }}
          >
            last name
          </p>
        }
        variant="standard"
      />

      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          label={
            <p
              style={{
                fontSize: ".8rem",
                textTransform: "uppercase",
                color: "#FF6700",
                fontWeight: "700",
              }}
            >
              date of birth
            </p>
          }
          inputFormat="MM/dd/yyyy"
          name="dateOfBirth"
          disabled
          value={formData.dateOfBirth}
          onChange={handleDateChange}
          renderInput={(params) => <TextField variant="standard" {...params} />}
          date={new Date(formData.dateOfBirth)}
        />
      </LocalizationProvider>

      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="demo-simple-select-standard-label">
          {
            <p
              style={{
                fontSize: ".8rem",
                textTransform: "uppercase",
                color: "#FF6700",
                fontWeight: "700",
              }}
            >
              gender
            </p>
          }
        </InputLabel>
        <Select
          name="gender"
          disabled
          value={formData.gender}
          onChange={handleChange}
          label="Gender"
        >
          <MenuItem value="">
            <em>Select gender</em>
          </MenuItem>
          <MenuItem value="MALE">Male</MenuItem>
          <MenuItem value="FEMALE">Female</MenuItem>
        </Select>
      </FormControl>
      <NumberFormat
        value={formData.phoneNumber}
        onChange={handleChange}
        name="phoneNumber"
        disabled
        label={
          <p
            style={{
              fontSize: ".8rem",
              textTransform: "uppercase",
              color: "#FF6700",
              fontWeight: "700",
            }}
          >
            phone no
          </p>
        }
        variant="standard"
        customInput={TextField}
        format="+ (###) - (0)## - #### - ####"
        mask="_"
      />
      <TextField
        value={formData.officeAddress}
        onChange={handleChange}
        name="officeAddress"
        label={
          <p
            style={{
              fontSize: ".8rem",
              textTransform: "uppercase",
              color: "#FF6700",
              fontWeight: "700",
            }}
          >
            address
          </p>
        }
        variant="standard"
      />
    </Stack>
  );
}
